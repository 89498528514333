@import 'variables';

$breadcrumb-height: 3.5vh;
$breadcrumb-top-margin: 1vh;
$breadcrumb-left-margin: 0.75vw;
$breadcrumb-actions-right-margin: 0.5vw;
$functionality-font-size: 1.4vmin;

// Styles for Page Breadcrumb
.page-breadcrumb-container {
  display: flex;
  align-items: center;
  padding: 0 0.2vh 0.2vh;
  height: $breadcrumb-height;
  top: $breadcrumb-top-margin;
  background-color: rgba(39, 39, 39, 0.6);
  border-radius: $uruk-toolbox-border-radius;
  margin-left: $breadcrumb-left-margin;

  .page-breadcrumb {
    display: flex;
    align-items: center;
    padding: 1vmin;
    height: $breadcrumb-height;
    line-height: 1vh;

    uruk-svg {
      &.disabled {
        color: gray;
        pointer-events: none;
      }
    }

    .uruk-logo {
      height: 1.4vmin;
    }

    .page-title {
      padding-left: 0.5vw;
      padding-right: 0.42vw;
      margin-left: 0.5vw;
      border-left: 1px solid #C9C9C9;
      font-size: $functionality-font-size;
      line-height: 1.75vh;

      // matches the page title input in edit mode
      input {
        border: $dashed-border-width dashed white !important;
        width: 15vmin;
        padding-left: 0.1rem;
        padding-right: 0;
        font-size: $functionality-font-size;
        max-width: none;
      }

      // matches the title of root menu item
      span {
        color: #d1d1d1;
      }
    }

    .page-icon {
      font-size: $functionality-font-size;
      align-self: flex-end;
      pointer-events: none;

      &.dashed-border {
        pointer-events: auto;
        cursor: pointer;
        border: $dashed-border-width dashed;
        border-radius: 0.25vmin;
        padding: 0.3vmin
      }
    }
  }

  .breadcrumb-arrows {
    display: flex;
    align-items: center;
    border-left: $uruk-expansion-border;
    padding-left: 0.1vw;
    height: 100%;

    nb-icon {
      font-size: 1.2vmin;
      cursor: pointer;
    }
  }

  // matches the additional breadcrumb div in edit mode
  .breadcrumb-edit-page {
    display: flex;
    align-items: center;


    > div {
      display: flex;
      align-items: center;
      background-color: #1263dd;
      border-radius: 0.3vmin;
      padding: 0.1vmin;

      .breadcrumb-edit-icon-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2vmin;
        height: 2vmin;
        font-size: 1vmin;
        background-color: transparent;

        // matches the icon used to edit page
        &:first-of-type {
          color: gray;
          pointer-events: none;
        }

        // matches the icon used to save updated page
        &:last-of-type {
          cursor: pointer;
          border-left: 1px solid #ffffff;
        }
      }
    }

    // matches the icon used to close edit mode
    .close-edit-page {
      position: relative;
      left: 1vmin;
      top: 1vmin;
      font-size: 0.8vmin;
      cursor: pointer;
      background-color: transparent;
    }
  }
}

.functions-menu {
  position: absolute;
  display: flex;
  z-index: 1001;
  right: 0;
  top: $breadcrumb-top-margin;

  .control-actions {
    background-color: rgba(39, 39, 39, 0.6);
    margin-right: $breadcrumb-actions-right-margin;

    .control-button {
      cursor: pointer;
      padding: 0 0.75vmin 0 0.75vmin;
      border: none;
      width: 3.5vmin;
      height: 3.5vmin;

      &.thin-border-right {
        border-right: 1px solid;
      }

      // matches the delete page icon
      &.delete-page {
        display: flex;
        justify-content: center;
        background-color: red;
        width: 3vmin;
        border-radius: $uruk-toolbox-border-radius;
        margin-left: 0.75vmin;
        pointer-events: auto;

        &.disabled {
          pointer-events: none;
          background-color: #ff7979;
        }

        i {
          font-size: $functionality-font-size;
          color: white;
        }
      }
    }

    .control-icon {
      color: white;
      padding: 10px;
      border-radius: 50%;
      background-color: #4e4e4e;
    }

    .control-icon:hover {
      color: #8f9bb3;
    }

    &.right {
      margin-left: 0.5vw;
    }
  }

}

/**
  Page List View
*/
.uruk-list {
  width: 100%;
  height: calc(100% - #{$breadcrumb-height} - #{$breadcrumb-top-margin});
  margin-top: $breadcrumb-top-margin;

  $user-list-status-height: 3.5vh;

  .uruk-list-status {
    display: flex;
    align-items: center;
    justify-content: normal;

    width: 100%;
    height: $user-list-status-height;
    padding: 1vmin 0.5vmin 1vmin 1vmin;
    background: #1E1E1E;
    border-top-left-radius: 0.5vmin;
    border-top-right-radius: 0.5vmin;

    .status-text {
      font-family: Roboto;
      font-weight: bold;
      font-size: 1.2vmin;
      color: #797979;
      margin-left: 0.25vw;

      &.total {
        font-size: 1.4vmin;
        margin-left: 0;
      }

      &.title {
        font-size: 1.4vmin;
        color: white;
        margin-left: 0;
      }
    }

    // Container for column selection dropdown
    .column-selection {
      // Add padding and margin as needed
      margin-right: 1vmin;

      // Set the width and max-width to control layout
      width: 100%;
      max-width: 200px;

      // Flexbox to center the content
      display: flex;
      justify-content: center;
      align-items: center;

      // Style nb-select component
      nb-select {
        // Full width to match container
        width: 100%;
      }

      // Style nb-option component
      nb-option {
        // Optional: Selected option style
        &.selected {
          background-color: #b2ebf2;
        }
      }
    }


    .status-count {
      font-family: Roboto;
      font-size: 1.2vmin;
      color: #C9C9C9;
      margin-left: 0.25vw;
    }

    .list-multi-actions {
      display: flex; /* Use flexbox layout */
      align-items: center; /* Align items vertically in the center */
      gap: 1vmin;
      margin-right: 1vmin;

      &.disabled {
        background-color: transparent;
        pointer-events: none;
        opacity: 0.5;
      }

      .list-action-button {
        height: 2.5vmin;
        border-radius: $uruk-toolbox-border-radius;
        background: rgba(#828282, 0.3);
        font-size: 1.1vmin;
        cursor: pointer;
        padding-right: 0.5vmin;
        padding-left: 0.5vmin;
      }

      .icon-text-container {
        display: flex;
        align-items: center;
      }
      .icon-text-container nb-icon {
        margin-right: 8px;
      }
    }
  }

  .uruk-list-view {
    width: 100%;
    height: calc(100% - #{$user-list-status-height});
    overflow-y: hidden;
  }
}

.cancel-form-button {
  width: 2vh;
  height: 2vh;
  background: rgba(39, 39, 39, 0.6);
  cursor: pointer;
  margin-left: 0.65vmin;
  border-radius: $uruk-toolbox-border-radius;

  > i {
    font-size: 1vmin;
  }
}

.add-item-button {
  width: $breadcrumb-height;
  height: $breadcrumb-height;
  margin-left: 28vmin;
  cursor: pointer;

  i {
    font-size: 1.75vmin;
  }
}

.add-item-label {
  height: $breadcrumb-height;
  padding: 0.5vmin;
  margin-left: 28vmin;
  border-radius: $uruk-toolbox-border-radius;
  background-color: #232323;
  color: #d1d1d1;

  font-size: 1.4vmin;
  font-weight: bold;
  line-height: 1.75vh;
}

.uruk-list-breadcrumb {
  margin: 0 !important;;
}

.form-title {
  display: flex;
  align-items: flex-end;
}
.search-bar-container-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-left: auto;

  .device-buttons {
    border-color:#3B3D3F;
    height: 2.5vh;
    width: 2.5vh;
    margin-top:0.75vh;
    margin-right: 1vh;
    min-width: 60px;
    background-color: #3B3D3F;
    color: #8F8F8F;
  }
  .active-button {
    border-color: #1e90ff;
    background-color: #1e90ff;
    color: #E1EBFB;
  }
}
.search-bar-container {
  $search-bar-width: 10vw;

  display: flex;
  align-items: center;

  nb-form-field {
    max-width: $search-bar-width !important;

    > div {
      display: flex;
    }
  }

  input[type="text"], textarea {
    width: $search-bar-width !important;
    max-width: $search-bar-width !important;
    height: 3vh !important;
    background-color : #373737 !important;
    font-size: 1.1vmin;
    padding: 1vmin;
  }

  input::placeholder {
    font-family: Roboto !important;
    font-size: 1.1vmin !important;
    line-height: 2vh !important;
    color: white !important;
    opacity: 1 !important; /* Firefox */
  }

  button {
    background-color : #373737 !important;
    border-color: rgba(0,0,0,0) !important;
    border-width: 0 !important;
  }

  nb-icon {
    width: 1.5vh !important;
    height: 1.5vh !important;
    color: white !important;
  }

  .search-input-button {
    margin-right: 0.9vmin !important;
    padding: 0.25vmin 0.25vmin !important;
    cursor: pointer !important;
  }

  .filter-button {
    height: 3vh !important;
    width: 3vh !important;
    margin-left: 0.25vw !important;
    background: white !important;
  }
}
