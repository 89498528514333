@import 'variables';
@import 'layer-styles';
@import 'entity-styles';
@import 'icon-styles';
@import 'page-styles';

/* URUK WEB STYLES */

.nb-tab-custom-icon {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 0.8vw !important;
  height: 100% !important;
  font-size: 0.8vw !important;
}

// this style is to restrict Nebular's header component to the width of the menu. It, therefore, prevents header getting in front of the other elements in the page
nb-layout-header {
  nav {
    max-width: $uruk-sidebar-width;
  }
}

.nb-timepicker-container {
  background-color: #1E1E1E !important;
  border: 1px solid #1263DD !important;
  width: 20vmin;
  height: 40vmin;
  --timepicker-cell-text-font-family: 'Roboto', sans-serif, serif;
  --timepicker-cell-text-font-size: 1.2vmin;
}

.nb-timepicker-container .header-cell {
  display: none !important;
}

.nb-timepicker-container .actions-footer {
  padding: 1vmin !important;
  display: none;
}

.nb-timepicker-container nb-calendar-actions button {
  background-color: #1263DD !important;
  border-color: #1263DD !important;
  font-size: 1.2vmin !important;
}

// this style is to wrap messages in toasters
nb-toast {
  .message {
    white-space: pre-wrap;
  }
}

// matches the pages displayed in the 'platform' route for admins
.admin-page {
  padding: $uruk-side-page-padding;
  background-image: url("/assets/images/smart-cities.png"), linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8));
  background-blend-mode: overlay;
}

.cdk-overlay-container {
  nb-option-list {
    overflow-wrap: anywhere;

    &.size-small {
      nb-option.add-panel-font-size {
        font-size: $add-panel-component-font-size !important;
      }
    }
  }
}

.fa-shift-top {
  position: absolute;
  bottom: 50px;
  left: 18px;
}

.fa-shift-top-right {
  position: absolute;
  transform: rotate(45deg);
  bottom: 40px;
  left: 38px;
}

.fa-shift-right {
  position: absolute;
  left: 40px;
  bottom: 18px;
}

.fa-shift-bottom-right {
  position: absolute;
  transform: rotate(135deg);
  left: 38px;
  top: 25px;
}

.fa-shift-bottom {
  position: absolute;
  top: 50px;
  left: 18px;
}

.fa-shift-bottom-left {
  position: absolute;
  transform: rotate(-135deg);
  top: 25px;
  right: 38px;
}

.fa-shift-left {
  position: absolute;
  right: 40px;
  top: 18px;
}

.fa-shift-top-left {
  position: absolute;
  transform: rotate(-45deg);
  bottom: 40px;
  right: 38px;
}

.clickable {
  cursor: pointer;
}

.click-sensitive {
  pointer-events: auto;
  cursor: pointer;
}

.flex-centered {
  display: flex !important;
  justify-content: center;
  justify-items: center;
  align-items: center;
  text-align: center;
}

.fullscreen-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.no-pad {
  padding: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.no-space {
  padding: 0 !important;
  margin: 0 !important;
}

.with-border-radius {
  border-radius: $uruk-toolbox-border-radius !important;
}

// used for selecting/removing tags
.selected-tag-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1vh;
  padding-top: 0.5vw;
  border: .5px solid nb-theme(color-basic-600);

  .selected-tag {
    height: 3vh;
    line-height: 2.5vh;

    padding-left: 0.5vw;
    padding-right: 0.5vw;

    margin-left: 0.5vw;
    margin-bottom: 0.5vw;

    background: nb-theme(color-success-active);
    border-radius: 30px;

    label {
      color: white;
    }

    i {
      margin-left: 0.5vw;
      cursor: pointer;
      color: white;
      font-size: 1.4vmin;
    }
  }

  .clear-all {
    float: left;
    height: 3vh;
    margin-left: 0.5vw;
    margin-bottom: 0.5vh;
    border: 0.5vmin;
    font-size: 1.4vmin;
    text-transform: none !important;
  }
}

// clears the text transform for buttons
button {
  text-transform: none !important;
}

/* Form related styles */
.input-row {
  display: flex;
  align-items: center;
  //max-width: 100%;

  .input-label {
    flex: 1 1 20%;
    //max-width: 20%;
    margin-right: 1vmin;

    &.up {
      align-self: baseline;
      padding-top: 1vh;
    }
  }

  .input-value {
    flex: 4 4 80%;
    //max-width: 80%;

    input {
      width: 100%;
    }
  }
}

.action-icon {
  cursor: pointer;
}

// removes the 1px border around the video
video {
  clip-path: inset(1px 1px);
}

/* ASEL UI (OLD) STYLES */

//@font-face {
//  font-family: led-display;
//  src: url(~assets/font/BPdots.otf);
//}

.overlay-container {
  position: absolute;
  padding: 1.5vmin !important;
  //opacity: $opacity;
  z-index: 1000;
}

.side-container {
  width: $side-container-width;
  height: $side-container-height;
  top: $side-container-top;
  pointer-events: none;
  z-index: 999;

  &.right {
    right: 0;
  }
}

.bottom-container {
  display: flex;
  width: $bottom-container-width;
  height: $bottom-container-height;
  bottom: 0;
  padding-left: 0;
  padding-right: 0;
}

.centered-panel {
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.overlay-panel {
  //background: nb-theme(background-basic-color-1);
  background: #272727;
  box-shadow: $uruk-box-shadow;
  // matches the plus sign used to add new panels
  .fa-plus {
    position: absolute;
    left: 45%;
    top: 45%;
  }
}

.side-panel {
  width: 100%;
  display: inline-block;
  position: relative;
  margin-bottom: 6px;
  margin-top: 6px;
  background-color: rgba(39, 39, 39, 0.6);
  font-size: 3vmin;
  pointer-events: auto;

  &.side-panel-1 {
    height: 100%;
  }

  &.side-panel-2 {
    height: 49%;
  }

  &.side-panel-3 {
    height: 31%;
  }

  &.side-panel-4 {
    height: 24%;
  }

  &.dashed-border {
    border: $dashed-border-width dashed;
    border-radius: 0.7rem;
  }

  &.add-panel {
    height: 40px;
    line-height: 30px;
    background: nb-theme(background-basic-color-1);
    text-align: center;
    vertical-align: middle;
  }

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &.scrolling {
    overflow-y: auto;
    overflow-x: hidden;
  }

  &:hover {
    background-color: rgba(39, 39, 39, 1);
  }

  &:hover {
    .side-panel-content {
      .side-panel-settings {
        display: inline;
      }
    }
  }

  .close-button {
    display: none !important;
  }

  i {
    cursor: pointer;
  }

  i:hover {
    color: nb-theme(color-primary-400);
  }
}

.side-panel-content {
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 100%;

  .side-panel-header {
    width: $side-panel-header-width;
    min-height: $side-panel-header-height;
    display: table-row;
    overflow: auto;

    .side-panel-title {
      width: 70%;
      line-height: $side-panel-header-height;
      max-height: 2*$side-panel-header-height;
      display: flex;
      -webkit-line-clamp: 2; // number of lines to show
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      float: left;

      .close-button {
        margin-left: auto;
        margin-right: 0.75em;
        cursor: pointer;
      }

      .close-button:hover {
        transform: scale(1.2);
      }
    }

    .side-panel-settings {
      width: 30%;
      float: left;
      text-align: center;
      cursor: pointer;
      height: $side-panel-header-height;
      display: none;

      div {
        margin-left: 0.75em;
        float: right;
        height: $side-panel-header-height;
      }
    }
  }

  .side-panel-body {
    width: 100%;
    height: 100%;
    display: table-row;
  }

  .side-panel-footer {
    width: 100%;
    height: 25px;
    display: table-row;
  }
}

.bottom-panel {
  width: $bottom-panel-width;
  height: $bottom-panel-height;
  margin-left: 0.5em;
  margin-right: 0.5em;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

.dialog-panel {
  width: 60%;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  background: #3d3b3b;

  // for smart panel dialogs
  .side-panel-title {
    margin-top: 1rem;
    width: 100% !important;
    color: white !important;
  }
}

/** added this class in order to move dialogs to the center of the screen. */
.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
  justify-content: center;
  align-items: center;
}

.form-error {
  margin-left: 0.5em;
  color: nb-theme(color-danger-default);
}

.window {
  width: 50%;
  height: 50%;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: nb-theme(color-basic-500);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: nb-theme(background-basic-color-4);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: nb-theme(background-basic-color-4);
}

.changed {
  transition: background-color 0.5s linear 0s;
  background-color: black;
}

/* Form input styles */
.error-label {
  color: nb-theme(color-danger-default) !important;
}

.error-border {
  border: 0.15vmin solid nb-theme(color-danger-default) !important;
}

.form-error {
  margin-left: 0.5em;
  color: nb-theme(color-danger-default);
}

/* animations for blinking elements e.g. icons */
@keyframes bounce {

  0% {
    top: -100px;
  }

  10%, 20%, 53%, 80%, 100% {
    //-webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    top: 0;
  }

  40%, 43% {
    transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    top: -45px;
  }

  70% {
    transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
    top: -20px;
  }

  90% {
    top: -7px;
  }
}

@keyframes fade {
  from {
    opacity: 0.3;
  }
}

.blinking {
  animation-name: bounce, fade;
  animation-duration: 1s, 1s;
  animation-delay: 0s, 1500ms;
  animation-iteration-count: 1, infinite;
  animation-fill-mode: both, none;
}

input {
  &:focus {
    border-color: #1263DD !important;
    background-color: #1E1E1E !important;
  }
}

input.status-basic, button.select-button {
  background-color: #4e4e4e !important;
  border-color: #272727 !important;
}

textarea {
  background-color: #2B2B2B !important;
}

// TODO theme should be selected in a proper way instead of using important statements
nb-card {
  background: #3d3b3b !important;

  nb-card-header {
    border-bottom: 1px solid #272727 !important;
  }

  nb-card-footer {
    border-top: 1px solid #272727 !important;
  }
}

nb-option {
  background-color: #1E1E1E !important;
}

nb-option-group {
  background-color: #1E1E1E !important;
}

nb-option:hover {
  background-color: #1E1E1E !important;
}

nb-option-list {
  border-color: #1263DD !important;
}

nb-toast {
  z-index: 99999;
}

nb-select {
  &.open {
    border-color: #1263DD !important;
    background-color: #1E1E1E !important;

    button {
      background-color: #1E1E1E !important;
      color: #1263DD !important;
    }
    nb-icon {
      background-color: #1263DD !important;
    }
  }
}

nb-popover {
  background: rgba(35, 35, 35, 0.8) !important;
  box-shadow: 0 0.1vmin 0.1vmin rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(0.1vmin);
  border-radius: 0.5vmin;

  span {
    display: none;
  }

  nb-overlay-container {
    div.primitive-overlay {
      font-size: 1.3vmin !important;
      padding: 1vmin;
    }

    // custom popover styles
    .kpi-detail-popover {
      font-family: Roboto !important;
      font-size: 1.3vmin !important;
      padding: 1vmin !important;
      max-width: 50vmin;

      .kpi-title {
        color: #D1D1D1;
      }

      .kpi-description {
        color: #979797;
      }
    }
  }
}

.icon-tooltip {
  left: 2.5vmin;
  line-height: 2vmin;
  position: absolute;
  padding: 1vmin;
  white-space: nowrap;

  background: rgba(35, 35, 35, 0.8) !important;
  box-shadow: 0 0.1vmin 0.1vmin rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(0.1vmin);
  border-radius: 0.5vmin;
}

@media screen and (max-width: 1750px) {
  .icon-tooltip {
    left: 3vmin;
    top: 100%;
  }
}

.notifications-tooltip {
  position: absolute;
  right: 40%;
  top: 70%;
}

.regular-value {
  color: $regular-color;
}

.warning-value {
  color: $warning-color;
}

.custom-vertical-scrollbar {
  &::-webkit-scrollbar {
    width: .6vw;
  }

  &::-webkit-scrollbar-track {
    margin-top: 1vh;
    margin-bottom: 1vh;
    border-radius: 9px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 9px;
  }
}

.full-view-height {
  height: 100vh;
}

body.compacted {
  // dialog
  .cdk-overlay-container {
    .success-dialog-backdrop {
      left: $uruk-sidebar-icon-container-width !important;

      + .cdk-global-overlay-wrapper {
        left: $uruk-sidebar-icon-container-width;
        width: calc(100% - $uruk-sidebar-icon-container-width);
      }
    }
  }

  // menu
  .menu-container {
    margin-top: 2.5vh;
  }

  .header-container {
    margin-top: 0 !important;

    .logo-container {
      > div {
        &:first-of-type {
          a {
            padding-bottom: 1vh !important;
          }
        }
      }
    }
  }

  .main-container-fixed {
    .scrollable {
      padding-top: 4vh !important;
    }
  }
}
