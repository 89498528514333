/** Uruk Icon Styles */

// a generic icon container in various components to enable responsiveness
.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 1.5vmin;
  height: 1.5vmin;
  font-size: 1.1vmin;

  &.left-margined {
    margin-left: 1vmin;
  }
}

.uruk-icon {
  cursor: pointer;

  svg {
    fill: currentColor;

    &:hover {
      fill: nb-theme(color-primary-400);
    }
  }

  &.left-margined {
    margin-left: 0.5vmin;
  }
}

// Uruk icon styles for layers container
.layer-container {
  .layer {

    .uruk-icon {
      svg {
        fill: #4e4e4e;
      }

      &.selected {
        svg {
          fill: white;
        }
      }
    }
  }
}

// Uruk icon styles for functions menu
.functions-menu {
  // matches the case where function menu items are disabled in edit mode
  &.disabled {
    .control-actions {
      background-color: transparent;
      pointer-events: none;
    }

    .control-button {
      svg {
        fill: #4e4e4e !important;
      }

      &.delete-page {
        svg {
          fill: #c9c9c9 !important;;
        }
      }
    }

    uruk-date-selection {
      .date-selection {
        pointer-events: none;

        input {
          background-color: #3d3b3b !important;
          border-color: #3d3b3b !important;

          &::placeholder {
            color: #4e4e4e !important;
          }
        }

        svg {
          path {
            fill: #4e4e4e !important;
          }
        }
      }
    }
  }

  .panel-visibility-icon-container {
    .toggle-icon {
      svg {
        fill: #4e4e4e;
      }

      &.selected {
        svg {
          fill: white;
        }
      }
    }
  }
}

// Uruk icon styles for page breadcrumb
.page-breadcrumb {
  .uruk-icon {
    margin-left: 0.75vw;
    width: 1.25vmin;
    height: 1.25vmin;
  }
}
