
/*  Parking Styles & Vehicles */

.entity-tooltip {
  text-align: center;
  font-size: 1.3vmin !important;

  .text {
    color: #D1D1D1;
  }

  .title {
    color: #979797;
  }
}

/*  Weather Pollution Observation Styles */

.weather-pollution-badge {
  margin-left: 0.5vmin;
}

.weather-pollution-badge-hazardous {
  color: $weather-pollution-hazardous !important;
}

.weather-pollution-badge-good {
  background: rgba($weather-pollution-good, 0.25);
  color: $weather-pollution-good;
}

.weather-pollution-badge-moderate {
  background: rgba($weather-pollution-moderate, 0.25);
  color: $weather-pollution-moderate;
}

.weather-pollution-badge-sensitive {
  background: rgba($weather-pollution-unhealthy-for-sensitive, 0.25);
  color: $weather-pollution-unhealthy-for-sensitive;
}

.weather-pollution-badge-unhealthy {
  background: rgb($weather-pollution-unhealthy, 0.25);
  color: $weather-pollution-unhealthy;
}

.weather-pollution-badge-very-unhealthy {
  background: rgb($weather-pollution-very-unhealthy, 0.25);
  color: $weather-pollution-very-unhealthy;
}

.weather-pollution-badge-hazardous {
  background: rgb($weather-pollution-hazardous, 0.25);
  color: $weather-pollution-hazardous;
}

/*  Defibrillator Styles */

.defibrillator-badge {
  margin-left: 0.5vmin;
}

.defibrillator-badge-avaliable {
  background: rgba($defibrillator-avaliable, 0.25);
  color: $defibrillator-avaliable;
}

.defibrillator-badge-lowbattery {
  background: rgba($defibrillator-lowbattery, 0.25);
  color: $defibrillator-lowbattery;
}

.defibrillator-badge-pednotengaged {
  background: rgba($defibrillator-pednotengaged, 0.25);
  color: $defibrillator-pednotengaged;
}

.defibrillator-badge-error {
  background: rgba($defibrillator-error, 0.25);
  color: $defibrillator-error;
}

.defibrillator-badge-used {
  background: rgb($defibrillator-used, 0.25);
  color: $defibrillator-used;
}

.defibrillator-badge-notconnected {
  background: rgb($defibrillator-notconnected, 0.25);
  color: $defibrillator-notconnected;
}

/* Train Styles*/

.train-badge-avaliable {
  background: rgba($train-avaliable, 0.25);
  color: $train-avaliable;
}

.train-badge-error {
  background: rgba($train-error, 0.25);
  color: $train-error;
}
