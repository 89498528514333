// import Nebular Theme System and the default theme
@import '~@nebular/theme/styles/theming';
@import '~@nebular/theme/styles/themes/dark';

/* URUK WEB VARIABLES */

$uruk-background-color: #3D3B3B;
$uruk-border-color: rgba(235, 235, 235, 0.5);
$uruk-border: 1px solid $uruk-border-color;
$uruk-box-shadow: 5px 5px 5px #222222;
$uruk-button-background-color: #464646;
$uruk-button-border-radius: 0.5vmin;
$uruk-chart-text-color: rgba(125, 125, 125, 1);
$uruk-dialog-background-color: #2C2C2C !important;
$uruk-expansion-border: 0.01vh solid #C9C9C975;
$uruk-icon-background: #464646;
$uruk-icon-color: #EBEBEB;
$uruk-logo-text-color: #517CA2;
$uruk-page-title-font-size: 1.45vmin; // e.g. h6 titles
$uruk-panel-box-shadow: -5px 4px 4px rgba(0, 0, 0, 0.25);
$uruk-panel-color: rgba(39, 39, 39, 0.9);
$uruk-sidebar-content-width: 10vw;
$uruk-sidebar-icon-container-width: 2vw;
$uruk-sidebar-width: $uruk-sidebar-content-width + $uruk-sidebar-icon-container-width;
$uruk-toolbox-border-radius: 0.5vmin;
$dashed-border-width: 0.3vmin;
$add-panel-component-font-size: 1.25vmin;
$uruk-side-page-padding: 1vh 0.75vw;

/* RESPONSIVE URUK VARIABLES */

$uruk-sidebar-content-width_ipad: 12vw;
$uruk-sidebar-icon-container-width_ipad: 3vw;
$uruk-sidebar-width_ipad: $uruk-sidebar-content-width_ipad + $uruk-sidebar-icon-container-width_ipad;

/* ASEL UI (OLD) VARIABLES */

$side-container-width: 22%;
$side-container-height: 95%;
$side-container-top: 7vh;
$bottom-container-width: 100-2*$side-container-width;
$bottom-container-height: 25%;

$side-panel-header-width: 100%;
$side-panel-header-height: 25px;

$bottom-panel-width: 100%;
$bottom-panel-height: 100%;

$regular-color: nb-theme(color-success-400);
$warning-color: nb-theme(color-danger-400);

/* Colors of weather pollution labels */
$weather-pollution-good: #34a522;
$weather-pollution-moderate: #e3bb2c;
$weather-pollution-unhealthy-for-sensitive: #f96b1b;
$weather-pollution-unhealthy: #f91b1b;
$weather-pollution-very-unhealthy: #d2007e;
$weather-pollution-hazardous: #980000;

/* Colors of defibrillator labels */
$defibrillator-avaliable: #34a522;
$defibrillator-lowbattery: #e3bb2c;
$defibrillator-pednotengaged: #f96b1b;
$defibrillator-error: #f91b1b;
$defibrillator-used: #d2007e;
$defibrillator-notconnected: #808080;


/* Colors of train labels */
$train-avaliable: #34a522;
$train-error: #e32c3b;
