.map-marker-icon {
  height: 4.5vh;
}

.map-marker-html {
  height: 4.5vh;
  width: 4.5vh;
}

.well-cluster-icon {
  background-color: #4f8fe2;
  opacity: 0.9;
  border-radius: 50%;

  span {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.store-cluster-icon {
  background-color: #88d1cc;
  opacity: 0.9;
  border-radius: 50%;

  span {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.riser-cluster-icon {
  background-color: #d9b3ff;
  opacity: 0.9;
  border-radius: 50%;

  span {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
